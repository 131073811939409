import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import BackButton from '../../components/BackButton';
import Button from '../../components/Button';
import CircularProgress from '../../components/CircularProgress';
import DesktopListItem from '../../components/desktop/ListItem';
import SearchableInput from '../../components/forms/SearchableInput';
import Checkmark from '../../components/icons/Checkmark';
import List from '../../components/List';
import SpecificUserLanguagePreferences from '../../components/SpecificUserLanguagePreferences';
import Typography from '../../components/Typography';
import { LANGUAGES, SHORTCUTS } from '../../constants';
import { FeatureContext } from '../../contexts/FeatureContext';
import { LocaleContext } from '../../contexts/LocaleContext';
import { SelectionContext } from '../../contexts/SelectionContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import { UsersContext } from '../../contexts/UsersContext';
import Item from '../../helpers/Item';
import Shortcuts from '../../helpers/Shortcuts';
import Step from '../../helpers/Step';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    maxWidth: '33.75rem',
    minWidth: '25.125rem',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflow: 'hidden',
  },
  header: {
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '4rem',
    minHeight: '4rem',
    padding: '1.125rem 0',
    width: '100%',
  },
  list: {
    flexGrow: 1,
    overflowY: 'auto',
  },
  loading: {
    padding: '4rem 2rem',
  },
  section: {
    padding: '0 1.25rem 1.25rem',
  },
  seeAllStaff: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1.5rem',
  },
  select: {
    alignItems: 'center',
    borderRadius: theme.borderRadius.default,
    color: theme.palette.secondary[500],
    display: 'flex',
    minHeight: '1.5rem',
    padding: '0.5rem 0.75rem',
  },
  title: {
    paddingTop: '1.25rem',
  },
  withIcon: {
    color: theme.palette.success[500],
    marginLeft: theme.leading.xxs,
    display: 'flex',
    '& svg:first-child': {
      fill: theme.palette.success[500],
      marginRight: '0.5rem',
    },
  },
}));

const searchFieldName = 'searchable-users';

const Users = ({
  loading,
  previous,
  previousStep,
  resetRange,
  setInformation,
  showBack,
}) => {
  const classes = useStyles({ theme: useTheme() });
  const intl = useIntl();

  const [locale] = useContext(LocaleContext);
  const { showStaffLanguages } = useContext(SettingsContext);
  const { spokenLanguages } = useContext(FeatureContext);
  const {
    getCategoryUsers,
    getUsers,
    setState,
    supportedLanguages,
    supportedLanguagesTranslations,
    users,
  } = useContext(UsersContext);
  const [{ settings, shortcuts, user, userCategory }, setSelections] =
    useContext(SelectionContext);
  const [query, setQuery] = useReducer((state, newState) => newState, '');
  const [preferences, setPreferences] = useReducer(
    (state, newState) => newState,
    {},
  );
  const hasPreferredUser = settings?.preferred_staff;

  const [waitingForUsers, setWaiting] = useState(true);

  useEffect(() => {
    if (userCategory && users === null) {
      if (!waitingForUsers) {
        setWaiting(true);
      }

      getCategoryUsers().then((result) => {
        setWaiting(result);
      });
    } else {
      setWaiting(false);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, waitingForUsers, userCategory, users]);

  // when not using shortcuts or if shortcut has no selections, fetch users for normal booking flow
  useEffect(() => {
    if (
      (shortcuts?.length === 0 || !Shortcuts.exists(SHORTCUTS.USER_CATEGORY)) &&
      !users
    ) {
      if (!waitingForUsers) {
        setWaiting(true);
      }

      getUsers().then((result) => {
        setWaiting(result);
      });
    } else {
      setWaiting(false);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, users]);

  useEffect(() => {
    if (
      !Shortcuts.exists(
        SHORTCUTS.USER || Shortcuts.exists(SHORTCUTS.USER_CATEGORY),
      ) &&
      users?.length === 0
    ) {
      if (!waitingForUsers) {
        setWaiting(true);
      }

      getUsers().then((result) => {
        setWaiting(result);
      });
    } else {
      setWaiting(false);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale, users]);

  useEffect(() => {
    if (users?.length === 1) {
      setTimeout(() => {
        setInformation({ loading: true });
        setSelections({ date: null, user: Item.first(users, null) });
      }, 1);
    }

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const changeLocalePreferences = (preferences) => {
    setPreferences(preferences);
  };

  const getAriaLabel = (item) => {
    const locales = spokenLanguages
      ? item.spokenLanguages
      : item.supportedLocales;
    const ariaLabelLanguages = [];

    locales.forEach((abbr) => {
      const language = spokenLanguages
        ? supportedLanguagesTranslations[locale][abbr]
        : intl.formatMessage({
            id: `Languages.${LANGUAGES[abbr].toLowerCase()}`,
          });

      ariaLabelLanguages.push(language);
    });

    // Outputs similar to {name} speaks {English, Dutch, French}
    return intl.formatMessage(
      { id: 'DateTime.locale_badge_label' },
      {
        name: item.name,
        language: ariaLabelLanguages.join(', '),
      },
    );
  };

  const getBadges = (item) => {
    if (!supportedLanguages) {
      return null;
    }

    const locales = spokenLanguages
      ? item.spokenLanguages
      : item.supportedLocales;
    return locales.map((abbr) => ({
      label: abbr,
    }));
  };

  const filterUsers = () => {
    const options = Item.map(preferences, (preference) =>
      preferences[preference] ? preference : null,
    ).filter(Boolean);

    const filtered =
      supportedLanguages?.length > 1 && options.length > 0
        ? users?.filter((user) =>
            spokenLanguages
              ? user.spokenLanguages.some((locale) => options.includes(locale))
              : user.supportedLocales.some((locale) =>
                  options.includes(locale),
                ),
          )
        : users;

    if (user && !filtered?.find((person) => person.id === user.id)) {
      setSelections({ user: null });
    }

    if (query.length < 2) {
      return filtered;
    }

    return filtered.filter((user) => user.name.toLowerCase().includes(query));
  };

  const handleSearch = ({ currentTarget: { value } }) => {
    setQuery(value.toLowerCase());
  };

  const setUser = ({
    currentTarget: {
      dataset: { id },
    },
  }) => {
    if (loading) {
      return;
    }

    if (user && user.id === id) {
      setSelections({ date: null, user: null });
      setInformation({ loading: true, earliestDate: null });

      return;
    }

    // used for next availability feature only
    resetRange();

    setSelections({ date: null, user: users?.find((item) => item.id === id) });
    setInformation({ loading: true, earliestDate: null });
  };

  const showAllUsers = () => {
    if (loading) {
      return;
    }

    if (users?.length && !loading) {
      setState({ users: [], supportedLanguages: null });
      setSelections({
        settings: { ...settings, preferred_staff: null, sort: null },
        userCategory: null,
      });
    }
  };

  const header = showBack ? (
    <header className={classes.header}>
      <BackButton
        previous={() => {
          setInformation({ merge: false, slots: {} });
          previous();
        }}
        text={<FormattedMessage id="Ui.back" />}
        title={Step.getBackString(intl, previousStep)}
      />
    </header>
  ) : null;

  return (
    <div className={classes.root}>
      {header}
      <section className={classes.content}>
        <header className={classNames(classes.section, classes.title)}>
          <Typography component="h1" variant="h5">
            <FormattedMessage id="DateTime.select_user" />
          </Typography>
        </header>
        {waitingForUsers ? (
          <div className={classes.loading}>
            <CircularProgress />
          </div>
        ) : users?.length ? (
          <>
            <section className={classes.section}>
              <SearchableInput name={searchFieldName} onChange={handleSearch} />
            </section>
            {supportedLanguages?.length > 1 && (
              <section className={classes.section}>
                <SpecificUserLanguagePreferences
                  languages={supportedLanguages}
                  setPreferences={changeLocalePreferences}
                />
              </section>
            )}
            <section className={classes.list}>
              <List id={`searchable-input-${searchFieldName}`}>
                {filterUsers()?.map((item) => (
                  <DesktopListItem
                    action={setUser}
                    adornment={
                      user && item.id === user.id ? (
                        <div
                          className={classNames(
                            'successAdornment',
                            classes.select,
                            classes.withIcon,
                          )}
                        >
                          <Checkmark
                            altText={intl.formatMessage({
                              id: 'Svg.alt_text.user_selected',
                            })}
                            ariaHidden
                          />
                          <Typography variant="button">
                            <FormattedMessage id="Ui.selected" />
                          </Typography>
                        </div>
                      ) : (
                        <div
                          className={classNames('adornment', classes.select)}
                        >
                          <Typography variant="button">
                            <FormattedMessage id="Ui.select" />
                          </Typography>
                        </div>
                      )
                    }
                    ariaLabel={getAriaLabel(item)}
                    badges={showStaffLanguages ? getBadges(item) : null}
                    id={item.id}
                    imageUrl={item.profilePhotoUrl}
                    key={item.id}
                    primary={item.name}
                    secondary={item.jobTitle}
                  />
                ))}
              </List>
              {hasPreferredUser ? (
                <div className={classes.seeAllStaff}>
                  <Button
                    fullWidth={false}
                    onClick={showAllUsers}
                    variant="smallOutlined"
                  >
                    <FormattedMessage id="UserPreference.see_all_staff" />
                  </Button>
                </div>
              ) : null}
            </section>
          </>
        ) : null}
      </section>
    </div>
  );
};

Users.propTypes = {
  loading: PropTypes.bool,
  previous: PropTypes.func.isRequired,
  previousStep: PropTypes.string,
  resetRange: PropTypes.func.isRequired,
  setInformation: PropTypes.func.isRequired,
  showBack: PropTypes.bool,
};

Users.defaultProps = {
  loading: true,
  previousStep: null,
  showBack: false,
};

export default Users;
