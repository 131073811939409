import { RESOURCE_CLIENT_VIEW, VISIBILITIES } from '../../constants';
import Url from '../Url';

const Locations = (client) => ({
  /**
   * @returns {Promise<any>}
   */
  find({ location, settings }) {
    // this will return an array of one location
    const inviteFilter = settings?.invite_only_resources
      ? '&filter[invite_only_resources]=1'
      : '';
    const lang = settings?.lang ? `&lang=${settings.lang}` : '';

    return client.get(
      `/api/v2/open/locations?filter[location]=${location}${inviteFilter}${lang}`,
    );
  },

  /**
   * @returns {Promise<any>}
   */
  findByExternalId(externalId) {
    const filters = {
      external_id: externalId,
      visibility: VISIBILITIES.PUBLIC,
    };

    // this will return an array of one location
    return client.get(Url.url('/api/v3/open/locations', filters));
  },

  /**
   * @returns {Promise<any>}
   */
  in({
    filters: {
      assigned,
      category,
      id,
      meetingMethod,
      service,
      settings,
      user,
      userCategory,
      visibility = VISIBILITIES.PUBLIC,
    } = {},
    sortBy = [],
  }) {
    const filters = {
      resource: RESOURCE_CLIENT_VIEW,
    };

    if (assigned) {
      filters.assigned = 1;
    }

    if (category) {
      filters.category = category.id;
    }

    if (meetingMethod) {
      filters.meeting_method = meetingMethod;
    }

    if (service) {
      filters.service = service.id;
    }

    if (user) {
      filters.user = user.id;
    }

    if (userCategory && !settings?.preferred_staff) {
      filters.user_category = userCategory.id;
    }

    if (visibility !== undefined) {
      filters.visibility = visibility;
    }

    if (id) {
      filters.id = id;
    }

    return client.get(Url.url('/api/v3/open/locations', filters, sortBy));
  },

  /**
   * @returns {Promise<any>}
   */
  all({
    filters: {
      assigned,
      category,
      coordinates,
      details: { city, country, region, regionless, virtual } = {},
      meetingMethod,
      radius,
      service,
      serviceCategory,
      settings,
      user,
      userCategory,
      visibility = VISIBILITIES.PUBLIC,
    } = {},
    sortBy = [],
  } = {}) {
    const filters = {
      resource: RESOURCE_CLIENT_VIEW,
    };

    if (assigned) {
      filters.assigned = 1;
    }

    if (category) {
      filters.category = category.id;
    }

    if (serviceCategory) {
      filters.service_category = serviceCategory.id;
    }

    if (coordinates) {
      filters.latitude = coordinates.latitude;
      filters.longitude = coordinates.longitude;
    }

    if (meetingMethod) {
      filters.meeting_method = meetingMethod;
    }

    if (service) {
      filters.service = service.id;
    }

    if (user) {
      filters.user = user.id;
    }

    if (userCategory && !settings?.preferred_staff) {
      filters.user_category = userCategory.id;
    }

    if (visibility !== undefined) {
      filters.visibility = visibility;
    }

    if (city) {
      filters.city = city;
    }

    if (country) {
      filters.country = country;
    }

    if (region) {
      filters.region = region;
    }

    if (regionless) {
      filters.regionless = true;
    }

    if (virtual) {
      filters.virtual = virtual;
    }

    if (radius) {
      filters.radius = radius;
    }

    if (settings?.invite_only_resources) {
      filters.invite_only_resources = settings.invite_only_resources;
    }

    return client.get(Url.url('/api/v3/open/locations', filters, sortBy));
  },

  /**
   * @param {number} location
   *
   * @returns {Promise<any>}
   */
  queueable(location) {
    return client.get(`/api/v3/open/locations/${location}/queues`);
  },

  /**
   * @param {Object} filters
   * @returns {Promise<any>}
   */
  regions({
    category,
    method,
    service,
    serviceCategory,
    settings,
    user,
    userCategory,
  } = {}) {
    const filters = {};

    if (category) {
      filters.category = category.id;
    }

    if (serviceCategory) {
      filters.service_category = serviceCategory.id;
    }

    if (method) {
      filters.meeting_method = method;
    }

    if (service) {
      filters.service = service.id;
    }

    if (user) {
      filters.user = user.id;
    }

    if (userCategory && !settings?.preferred_staff) {
      filters.user_category = userCategory.id;
    }

    if (settings?.invite_only_resources) {
      filters.invite_only_resources = settings.invite_only_resources;
    }

    return client.get(Url.url('/api/v3/open/regions', filters));
  },
});

export default Locations;
