import { SHORTCUTS, STEPS } from '../constants';
import Item from './Item';
import Resources from './Resources';

export default {
  /**
   * @returns {object}
   */
  details({ region: place = null } = {}) {
    const details = {};

    const city = this.get(SHORTCUTS.CITY);
    const region = this.get(
      SHORTCUTS.REGION,
      place && place.id ? place.id : null,
    );
    const country = this.get(SHORTCUTS.COUNTRY);

    if (city) {
      details.city = city;
    }

    if (country) {
      details.country = country;
    }

    if (region) {
      details.region = region;
    }

    return details;
  },

  /**
   * @param {string} identifier
   *
   * @returns {boolean}
   */
  exists(identifier) {
    const { shortcuts } = window.state;

    return Item.has(shortcuts, identifier);
  },

  /**
   * @param {string} identifier
   * @param {*} fallback
   *
   * @returns {*}
   */
  get(identifier, fallback = null) {
    const { shortcuts } = window.state;

    return Item.get(shortcuts, identifier, fallback);
  },

  /**
   * @param {string} identifier
   *
   * @returns {string|null}
   */
  identify(identifier) {
    return Item.get(this.get(identifier), 'id');
  },

  /**
   * @param {Object} shortcuts
   * @param {Object} selections
   *
   * @returns {Object}
   */
  overlay(shortcuts, selections) {
    if (selections === null) {
      return shortcuts;
    }

    const {
      shortcuts: {
        location,
        locationCategory,
        meeting_method: meetingMethod,
        notes,
        region,
        service,
        settings,
        user,
        user_category: userCategory,
      },
    } = window.state;

    if (location && location.id) {
      if (location.id !== selections.location?.id) {
        selections.location = shortcuts.location;
      }
    }

    if (
      locationCategory?.id &&
      locationCategory.id !== selections.locationCategory?.id
    ) {
      selections.locationCategory = shortcuts.locationCategory;
    }

    if (meetingMethod) {
      if (meetingMethod !== selections.meetingMethod) {
        selections.meetingMethod = shortcuts.meetingMethod;
      }
    }

    if (notes && !selections.notes) {
      selections.notes = shortcuts.notes;
    }

    if (region) {
      if (region !== selections.region?.id) {
        selections.region = shortcuts.region;
      }
    }

    if (service && service.id) {
      if (service.id !== selections.service?.id) {
        selections.service = shortcuts.service;
      }
    }

    if (settings) {
      selections.settings = shortcuts.settings;
    }

    if (user && user.id) {
      if (user.id !== selections.user?.id) {
        selections.user = shortcuts.user;
      }
    }

    if (userCategory && userCategory.id) {
      if (userCategory.id !== selections.userCategory?.id) {
        selections.userCategory = shortcuts.userCategory;
      }
    }

    return selections;
  },

  /**
   * @param {object} data
   *
   * @returns {object}
   */
  prepare(data, search) {
    const {
      additional_users: additionalUsers,
      booking_shortcut_id: shortcutId,
      client,
      location,
      location_category: locationCategory,
      meeting_method: meetingMethod,
      notes,
      region,
      service,
      settings,
      partner,
      user,
      user_category: userCategory,
    } = window.state.shortcuts;

    let shortcuts = { ...data, search };

    if (additionalUsers) {
      shortcuts = {
        ...shortcuts,
        additionalUsers: additionalUsers.map((addUser) =>
          Resources.formatUser(addUser),
        ),
      };
    }

    if (shortcutId) {
      shortcuts = {
        ...shortcuts,
        booking_shortcut_id: shortcutId,
      };
    }

    const hasAttributes =
      client &&
      (client.id ||
        client.attributes.external_id ||
        client.attributes.first_name ||
        client.attributes.last_name ||
        client.attributes.email ||
        client.attributes.cell_phone);

    if (hasAttributes) {
      shortcuts = {
        ...shortcuts,
        attendee: {
          externalId: client.attributes.external_id,
          firstName: client.attributes.first_name,
          lastName: client.attributes.last_name,
          email: client.attributes.email,
          cellPhone: client.attributes.cell_phone,
          homePhone: client.attributes.home_phone,
        },
      };
    }

    if (location && location.id) {
      shortcuts = {
        ...shortcuts,
        location: Resources.formatLocation(location),
      };
    }

    if (locationCategory?.id) {
      shortcuts = {
        ...shortcuts,
        locationCategory: { id: locationCategory.id },
      };
    }

    if (meetingMethod) {
      shortcuts = {
        ...shortcuts,
        meetingMethod,
        skip: {
          [STEPS.MEETING_METHODS]: true,
          [STEPS.LOCATION]: Boolean(location && location.id),
        },
      };
    }

    if (notes) {
      shortcuts = {
        ...shortcuts,
        notes,
      };
    }

    if (partner) {
      shortcuts = {
        ...shortcuts,
        partner,
      };
    }

    if (region) {
      shortcuts = {
        ...shortcuts,
        region: typeof region === 'string' ? { id: region } : region,
      };
    }

    if (service && service.id) {
      shortcuts = {
        ...shortcuts,
        service: Resources.formatService(service),
      };
    }

    if (settings) {
      shortcuts = {
        ...shortcuts,
        settings,
      };
    }

    if (user && user.id) {
      shortcuts = {
        ...shortcuts,
        user: Resources.formatUser(user),
        userPreference: { id: 'random' },
      };
    }

    if (userCategory && userCategory.id) {
      shortcuts = {
        ...shortcuts,
        userCategory: { id: userCategory.id },
      };
    }

    return shortcuts;
  },
};
