import classNames from 'classnames';
import { parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import React, { useContext } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Dates from '../../shared/helpers/Dates';
import urlMode from '../../shared/helpers/Mode';
import Phone from '../../shared/helpers/Phone';
import useDateTime from '../../shared/hooks/useDateTime';
import { MEETING_METHODS } from '../constants';
import { AppointmentContext } from '../contexts/AppointmentContext';
import { SelectionContext } from '../contexts/SelectionContext';
import { SettingsContext } from '../contexts/SettingsContext';
import { TimezoneContext } from '../contexts/TimezoneContext';
import { TimezonesContext } from '../contexts/TimezonesContext';
import { MOBILE, ViewModeContext } from '../contexts/ViewModeContext';
import { formatMultipleUsers } from '../helpers/AdditionalUsers';
import Settings from '../helpers/Settings';
import Alert from './Alert';
import { Anchor } from './Anchor';
import AppointmentDetailItem from './AppointmentDetailItem';
import Appointment from './icons/Appointment';
import LocationDirectionsButton from './LocationDirectionsButton';
import LocationPhoneCallButton from './LocationPhoneCallButton';
import TimezoneLabel from './TimezoneLabel';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  actions: {
    display: 'flex',
    flex: '0 0 auto',
    justifyContent: 'center',
  },
  button: {
    marginTop: '0.625rem',
  },
  buttons: {
    display: 'flex',
    '& > button': {
      marginRight: '0.5rem',
    },
  },
  content: {
    ...theme.components.html.default,
    '&$desktop': {
      marginLeft: '2.375rem',
    },
  },
  desktop: {},
  dialIn: {
    marginTop: '0.75rem',
  },
  icon: {
    color: theme.palette.primary[400],
    display: 'inline-flex',
    marginRight: '0.875rem',
  },
  root: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    padding: '1.5rem',
    marginBottom: '0.5rem',
  },
  spacing: {
    marginTop: '1rem',
  },
  title: {
    alignItems: 'center',
    display: 'flex',
    marginBottom: '1.25rem',
  },
}));

const AppointmentDetailsCard = () => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });
  const {
    formatters: { formatTime },
  } = useDateTime(true);
  const { showStaffPicker, showStaffAssigned } = useContext(SettingsContext);
  const [
    { additionalUsers, attendee, date, location, meetingMethod, service, user },
  ] = useContext(SelectionContext);
  const [timezone] = useContext(TimezoneContext);
  const timezones = useContext(TimezonesContext);
  const [appointment] = useContext(AppointmentContext);
  const mode = useContext(ViewModeContext);
  const cellPhoneIsMasked = attendee?.cellPhone?.includes('*');
  const cellPhone = cellPhoneIsMasked
    ? attendee?.cellPhone
    : Phone.format(attendee?.cellPhone);
  const showStaffName =
    Settings.shouldShowStaffPicker({ showStaffPicker }, location) ||
    Boolean(showStaffAssigned);

  const time = () => {
    const startDate = parseISO(appointment.startRaw);
    const endDate = parseISO(appointment.endRaw);
    // We are temporarily ignoring the destructuring-assignment rule explicitly.
    // There is a bug that was solved in a newer version of this plugin which
    // we will eventually be able to upgrade to once we can move off of
    // the current version of NodeJS in use.
    //
    // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
    //
    // eslint-disable-next-line react/destructuring-assignment
    const { name: timezoneName } = timezones[timezone] || {};

    const startZoned = utcToZonedTime(
      zonedTimeToUtc(
        startDate,
        Intl?.DateTimeFormat?.()?.resolvedOptions()?.timeZone,
      ),
      timezoneName || timezone,
    );
    const endZoned = utcToZonedTime(
      zonedTimeToUtc(
        endDate,
        Intl?.DateTimeFormat?.()?.resolvedOptions()?.timeZone,
      ),
      timezoneName || timezone,
    );

    return (
      <FormattedMessage
        id="AppointmentDetails.appointment_duration"
        values={{
          start: formatTime(startZoned),
          end: formatTime(endZoned),
          timezone: (
            // We are temporarily ignoring the destructuring-assignment rule explicitly.
            // There is a bug that was solved in a newer version of this plugin which
            // we will eventually be able to upgrade to once we can move off of
            // the current version of NodeJS in use.
            //
            // https://github.com/jsx-eslint/eslint-plugin-react/issues/3520
            //
            // eslint-disable-next-line react/destructuring-assignment
            <TimezoneLabel fallback={timezone} timezone={timezones[timezone]} />
          ),
        }}
      />
    );
  };

  const meetingLink = appointment.meetingLink;
  const hasVirtualInfo = user && (user.meeting_info || meetingLink);
  const givingFeedback = window.location.pathname === '/give-feedback';
  const isKiosk = urlMode.isKiosk();

  return (
    <div className={classes.root}>
      <Typography classes={{ root: classes.title }} component="h4" variant="h6">
        <span className={classes.icon}>
          <Appointment />
        </span>
        <FormattedMessage id="AppointmentDetails.appointment_details" />
      </Typography>
      <div
        className={classNames(
          classes.content,
          mode !== MOBILE && classes.desktop,
        )}
        data-testid="appointment-details-content"
      >
        <AppointmentDetailItem
          label={<FormattedMessage id="AppointmentDetails.service" />}
          primary={service.name}
        />
        {(meetingMethod === MEETING_METHODS.AT_LOCATION ||
          meetingMethod === null) &&
        location.physical ? (
          <>
            <AppointmentDetailItem
              label={<FormattedMessage id="AppointmentDetails.location" />}
              primary={location.name}
              secondary={location.formattedAddress}
            />
            {!isKiosk ? (
              <div className={classes.buttons}>
                <LocationDirectionsButton location={location} />
                <LocationPhoneCallButton location={location} />
              </div>
            ) : null}
          </>
        ) : null}
        {meetingMethod === MEETING_METHODS.PHONE_CALL &&
        cellPhone &&
        !givingFeedback ? (
          <AppointmentDetailItem
            label={<FormattedMessage id="AppointmentDetails.phone_call" />}
            primary={
              <FormattedMessage id="AppointmentDetails.we_will_call_you" />
            }
            secondary={
              cellPhoneIsMasked ? (
                <span
                  aria-label={intl.formatMessage(
                    { id: 'AppointmentDetails.phone_number_ending_in' },
                    {
                      number: cellPhone
                        .substring(cellPhone.length - 3)
                        .split('')
                        .join(' '),
                    },
                  )}
                  data-personal
                >
                  {cellPhone}
                </span>
              ) : (
                <span data-personal>{cellPhone}</span>
              )
            }
          />
        ) : null}
        {meetingMethod === MEETING_METHODS.VIDEO_CALL &&
        !givingFeedback &&
        hasVirtualInfo &&
        !isKiosk ? (
          <AppointmentDetailItem
            body={
              <>
                {meetingLink ? (
                  <>
                    <div className={classes.button}>
                      <Anchor
                        external
                        href={meetingLink}
                        type="button"
                        variant="subtitle"
                      >
                        <FormattedMessage id="AppointmentDetails.join_call" />
                      </Anchor>
                    </div>
                    {appointment.dialIn?.e164 ? (
                      <>
                        <div className={classes.dialIn}>
                          <Typography component="p" variant="subtitle">
                            <FormattedMessage
                              id="AppointmentDetails.join_by_phone"
                              values={{
                                a: (chunks) => (
                                  <a href={`tel:${appointment.dialIn.e164}`}>
                                    {chunks}
                                  </a>
                                ),
                                number: appointment.dialIn.international,
                              }}
                            />
                          </Typography>
                        </div>
                        {appointment.dialIn.pin ? (
                          <div>
                            <Typography component="p" variant="subtitle">
                              <FormattedMessage
                                id="AppointmentDetails.pin"
                                values={{ pin: appointment.dialIn.pin }}
                              />
                            </Typography>
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}
                {user.meeting_info && user.meeting_link === meetingLink ? (
                  <div className={classes.spacing}>
                    <Alert
                      message={
                        <div
                          dangerouslySetInnerHTML={{
                            __html: user.meeting_info,
                          }}
                        />
                      }
                      variant="info"
                    />
                  </div>
                ) : null}
              </>
            }
            label={<FormattedMessage id="AppointmentDetails.video_call" />}
          />
        ) : null}
        {meetingMethod === MEETING_METHODS.VIDEO_CALL &&
        !givingFeedback &&
        !hasVirtualInfo &&
        !isKiosk ? (
          <AppointmentDetailItem
            label={<FormattedMessage id="AppointmentDetails.video_call" />}
            primary={
              <FormattedMessage id="AppointmentDetails.we_will_send_video_info" />
            }
          />
        ) : null}
        <AppointmentDetailItem
          label={<FormattedMessage id="AppointmentDetails.date_time" />}
          primary={Dates.toDateMonthYear(date)}
          secondary={time(
            Dates.parse(date, timezone),
            Dates.parse(appointment.end, timezone),
          )}
        />
        {user && showStaffName ? (
          <AppointmentDetailItem
            label={<FormattedMessage id="AppointmentDetails.user" />}
            primary={formatMultipleUsers([user, ...additionalUsers])}
          />
        ) : null}
        <AppointmentDetailItem
          label={
            <FormattedMessage id="AppointmentDetails.confirmation_number" />
          }
          primary={appointment.confirmCode}
        />
      </div>
    </div>
  );
};

export default AppointmentDetailsCard;
